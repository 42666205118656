<template>
  <el-dialog title="视频监控" width="840px" :visible.sync="visible" @opened="initEZUIKit" @closed="closedDialog">
    <div class="video-box">
      <div id="video-container"></div>
      <div class="contral">
        <div class="row">
          <i class="el-icon-top-left" @mousedown="contralVideos(4)"></i>
          <i class="el-icon-top" @mousedown="contralVideos(0)"></i>
          <i class="el-icon-top-right" @mousedown="contralVideos(6)"></i>
        </div>
        <div class="row">
          <i class="el-icon-back" @mousedown="contralVideos(2)"></i>
          <div class="item-box">
            <div class="item">
              <i class="el-icon-zoom-out" title="缩小" @mousedown="contralVideos(9)"></i>
              <i class="el-icon-zoom-in" title="放大" @mousedown="contralVideos(8)"></i>
            </div>
            <div class="item">
              <i class="el-icon-circle-plus-outline" title="远聚焦" @mousedown="contralVideos(11)"></i>
              <i class="el-icon-remove-outline" title="近聚焦" @mousedown="contralVideos(10)"></i>
            </div>
          </div>
          <i class="el-icon-right" @mousedown="contralVideos(3)"></i>
        </div>
        <div class="row">
          <i class="el-icon-bottom-left" @mousedown="contralVideos(5)"></i>
          <i class="el-icon-bottom" @mousedown="contralVideos(1)"></i>
          <i class="el-icon-bottom-right" @mousedown="contralVideos(7)"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import EZUIKit from "ezuikit-js";
export default {
  name: "ysPlayer",
  title: "萤石云视频监控播放",
  data() {
    return {
      deviceSerial: "",
      visible: false,
      accessToken: "",
      url: "",
      channelNo: "",
      player: null,
    };
  },
  methods: {
    showPlayer(deviceSerial) {
      this.visible = true;
      this.deviceSerial = deviceSerial;
    },
    initEZUIKit() {
      let deviceSerial = this.deviceSerial;
      this.$http.get(this.$api.getYingShiYunToken, null, (res) => {
        this.accessToken = res;
        this.$http.post(this.$api.getPlayAddress, { deviceSerial }, (res1) => {
          let { url } = res1;
          this.player = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: "video-container",
            accessToken: this.accessToken,
            url: url, //ezopen://open.ys7.com/E93077754/1.hd.live
            template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
            //plugin: ['talk'],                       // 加载插件，talk-对讲
            footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
            audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
            // openSoundCallBack: data => console.log("开启声音回调", data),
            // closeSoundCallBack: data => console.log("关闭声音回调", data),
            // startSaveCallBack: data => console.log("开始录像回调", data),
            // stopSaveCallBack: data => console.log("录像回调", data),
            // capturePictureCallBack: data => console.log("截图成功回调", data),
            // fullScreenCallBack: data => console.log("全屏回调", data),
            // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
            width: 800,
            height: 450,
          });
        });
      });
    },
    contralVideos(direction) {
      let param = {
        channelNo: 1,
        deviceSerial: this.deviceSerial,
        direction: direction,
        speed: 1,
      };
      this.$http.post(
        this.$api.startControl,
        param,
        (res) => {
          setTimeout(() => {
            this.$http.post(this.$api.stopControl, param, (res) => {}, false);
          }, 2000);
        },
        false
      );
    },
    closedDialog() {
      this.player = null;
      if (document.getElementById("video-container")) {
        document.getElementById("video-container").innerHTML = "";
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.video-box {
  width: 800px;
  height: 450px;
  position: relative;
  .contral {
    position: absolute;
    z-index: 99;
    bottom: 0;
    left: 5px;
    margin: auto;
    width: 150px;
    height: 150px;
    color: #fff;
    .row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 50px;
      i {
        width: 25px;
        text-align: center;
        font-size: 20px;
        line-height: 25px;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;
        transition-duration: 0.5s;
      }
      .item-box {
        flex: 3;
        .item {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 10px 0;
        }
      }
    }
    &:hover {
      .row {
        i {
          opacity: 1;
        }
      }
    }
  }
}
</style>